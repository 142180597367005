import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import logo from './assets/samscape_tl_logo.png'; // Adjust the path if you place the logo in a different folder
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


function App() {
  const mainBlocks = {
    sleep: {
      label: 'Sleep Block',
      color: '#9B51E0',
      recommendedHours: 9
    },
    ibadat: {
      label: 'Ibadat Block',
      color: '#2F80ED',
      recommendedHours: 5
    },
    business: {
      label: 'Business/Rizq Block',
      color: '#27AE60',
      recommendedHours: 5
    },
    leisure: {
      label: 'Leisure Block',
      color: '#F2994A',
      recommendedHours: 5
    },
    unassigned: {
      label: 'Unassigned',
      color: '#828282',
      recommendedHours: 0
    }
  };

  const [personalInfo, setPersonalInfo] = React.useState({
    name: '',
    date: new Date().toISOString().split('T')[0]
  });

  const [sleepBlock, setSleepBlock] = React.useState({
    startTime: '22:00',
    endTime: '07:00',
    duration: 9
  });

  const [timeBlocks, setTimeBlocks] = React.useState(() => {
    const blocks = [];
    for (let i = 0; i < 48; i++) {
      const hour = Math.floor(i / 2);
      const minute = (i % 2) * 30;
      blocks.push({
        id: i,
        startTime: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
        endTime: minute === 30 
          ? `${(hour + 1).toString().padStart(2, '0')}:00`
          : `${hour.toString().padStart(2, '0')}:30`,
        assignedTo: null,
        activity: '',
        selected: false
      });
    }
    return blocks;
  });

  const [currentMainBlock, setCurrentMainBlock] = React.useState('');
  const [currentActivity, setCurrentActivity] = React.useState('');
  const [summary, setSummary] = React.useState({
    sleep: 9,
    ibadat: 0,
    business: 0,
    leisure: 0,
    unassigned: 15
  });

  const calculateDuration = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    let hours = endHour - startHour;
    let minutes = endMinute - startMinute;
    
    if (hours < 0) hours += 24;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
    
    return hours + (minutes / 60);
  };

  const isInSleepBlock = (timeStr) => {
    const [hour, minute] = timeStr.split(':').map(Number);
    const [sleepStartHour, sleepStartMinute] = sleepBlock.startTime.split(':').map(Number);
    const [sleepEndHour, sleepEndMinute] = sleepBlock.endTime.split(':').map(Number);
    
    const timeMinutes = hour * 60 + minute;
    const sleepStartMinutes = sleepStartHour * 60 + sleepStartMinute;
    const sleepEndMinutes = sleepEndHour * 60 + sleepEndMinute;
    
    if (sleepEndMinutes < sleepStartMinutes) {
      return timeMinutes >= sleepStartMinutes || timeMinutes < sleepEndMinutes;
    }
    return timeMinutes >= sleepStartMinutes && timeMinutes < sleepEndMinutes;
  };

  const updateSummary = React.useCallback(() => {
    const newSummary = {
      sleep: sleepBlock.duration,
      ibadat: 0,
      business: 0,
      leisure: 0,
      unassigned: 0
    };

    timeBlocks.forEach(block => {
      if (!isInSleepBlock(block.startTime)) {
        if (block.assignedTo) {
          newSummary[block.assignedTo] += 0.5;
        } else {
          newSummary.unassigned += 0.5;
        }
      }
    });

    setSummary(newSummary);
  }, [timeBlocks, sleepBlock.duration]);

  React.useEffect(() => {
    updateSummary();
  }, [timeBlocks, sleepBlock, updateSummary]);

  const handleSleepBlockChange = (field, value) => {
    setSleepBlock(prev => {
      const newBlock = { ...prev, [field]: value };
      return {
        ...newBlock,
        duration: calculateDuration(newBlock.startTime, newBlock.endTime)
      };
    });
  };

  const handleBlockSelection = (blockId) => {
    setTimeBlocks(prev => 
      prev.map(block => ({
        ...block,
        selected: block.id === blockId ? !block.selected : block.selected
      }))
    );
  };

  const handleBulkAssignment = () => {
    if (!currentMainBlock || !currentActivity) return;
    setTimeBlocks(prev => 
      prev.map(block => ({
        ...block,
        assignedTo: block.selected ? currentMainBlock : block.assignedTo,
        activity: block.selected ? currentActivity : block.activity,
        selected: false
      }))
    );
    setCurrentActivity('');
    setCurrentMainBlock('');
  };

  const handleDeleteBlock = (blockId) => {
    setTimeBlocks(prev => 
      prev.map(block => 
        block.id === blockId 
          ? { ...block, assignedTo: null, activity: '', selected: false }
          : block
      )
    );
  };

  const pieChartData = Object.entries(summary).map(([key, value]) => ({
    name: mainBlocks[key].label,
    value: value,
    color: mainBlocks[key].color
  }));

const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.setTextColor('#0a1f44');
    doc.text("Daily Schedule Planner", 105, 20, { align: "center" });
    doc.setFontSize(12);
    doc.setTextColor('#00b9d8');
    doc.text("As per farmaan of Aali Qadr Syedna Mufaddal Saifuddin Moula (TUS)", 105, 30, { align: "center" });
    doc.setTextColor('#000000');
    doc.text(`Name: ${personalInfo.name}`, 20, 40);
    doc.text(`Date: ${personalInfo.date}`, 20, 50);
    
    // Add timetable
    doc.setFontSize(12);
    doc.setTextColor('#0a1f44');
    doc.text("Timetable:", 20, 60);
    const startY = 70;

    // Create table
    doc.autoTable({
      head: [['Sr. No', 'Time Block', 'Type', 'Activity']],
      body: timeBlocks.map((block, index) => {
        const isSleepTime = isInSleepBlock(block.startTime);
        const blockType = isSleepTime ? 'Sleep Block' : 
                         (block.assignedTo ? mainBlocks[block.assignedTo].label : 'Unassigned');
        const activity = isSleepTime ? 'Sleep' : 
                        (block.activity || 'Unassigned');
        return [index + 1, `${block.startTime} - ${block.endTime}`, blockType, activity];
      }),
      startY: startY,
      theme: 'grid',
      styles: { cellPadding: 5, fontSize: 10, textColor: '#0a1f44' },
      headStyles: { textColor: '#0a1f44', fontStyle: 'bold' },
      columnStyles: {
        0: { cellWidth: 30 }, // Auto width for Sr. No
        1: { cellWidth: 40 }, // Auto width for Time Block
        2: { cellWidth: 40 }, // Auto width for Type
        3: { cellWidth: 60 }  // Auto width for Activity
      },
    });

    // Add new page for summary and pie chart
    doc.addPage();
    doc.setFontSize(12);
    doc.setTextColor('#0a1f44');
    doc.text("Schedule Summary:", 20, 20);
    
    // Create summary table with font colors
    doc.autoTable({
      head: [['Block Type', 'Hours Assigned']],
      body: pieChartData.map(data => [
        data.name,
        `${data.value.toFixed(1)} hours`
      ]),
      startY: 30,
      theme: 'grid',
      styles: { 
        cellPadding: 5,
        fontSize: 10,
        textColor: '#0a1f44',
        halign: 'left'
      },
      headStyles: {
        textColor: '#0a1f44',
        fontStyle: 'bold'
      },
      columnStyles: {
        0: { cellWidth: 50 }, // Auto width for Block Type
        1: { cellWidth: 30 }  // Auto width for Hours Assigned
      }
    });

    // Capture the pie chart as an image with high quality
    html2canvas(document.querySelector(".pie-chart-container"), { scale: 2, width: "400", height: "300"}).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      // Add pie chart to the right of the summary
      doc.addImage(imgData, 'PNG', 110, 20, 80, 80); // Adjust position and size as needed

      // Add logo and footer on the same page
      const logoWidth = 25;
      const logoHeight = 40;
      const pageHeight = doc.internal.pageSize.getHeight();
      
      // Position logo and text at the bottom of the page
      doc.addImage(
        logo, 
        'PNG', 
        (doc.internal.pageSize.getWidth() - logoWidth) / 2, 
        pageHeight - 40, 
        logoWidth, 
        logoHeight
      );
      
      doc.setFontSize(10); // Set smaller font size for footer text
      doc.text(
        "Developed by: Abde Syedna Ebrahim Rupawala",
        doc.internal.pageSize.getWidth() / 2,
        pageHeight - 8,
        { align: "center" }
      );

      // Save the PDF with custom Filename
      const fileName = `${personalInfo.name.trim() || 'untitled'}_schedule.pdf`;
      doc.save(fileName);
    });
};

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Daily Schedule Planner for Mumineen</h1>
      <p className="text-center text-lg mb-6">As per farmaan of Aali Qadr Syedna Mufaddal Saifuddin Moula (TUS)</p>
      
      {/* Input Fields for Name and Date */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Name</label>
        <input
          type="text"
          value={personalInfo.name}
          onChange={(e) => setPersonalInfo({ ...personalInfo, name: e.target.value })}
          className="w-1/2 p-2 border rounded"
          placeholder="Enter your name"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Date</label>
        <input
          type="date"
          value={personalInfo.date}
          onChange={(e) => setPersonalInfo({ ...personalInfo, date: e.target.value })}
          className="w-1/2 p-2 border rounded"
        />
      </div>

      {/* Sleep Block Configuration */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Sleep Block</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Start Time</label>
            <input
              type="time"
              value={sleepBlock.startTime}
              onChange={(e) => handleSleepBlockChange('startTime', e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">End Time</label>
            <input
              type="time"
              value={sleepBlock.endTime}
              onChange={(e) => handleSleepBlockChange('endTime', e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Duration</label>
            <div className="flex items-center space-x-2">
              <div className="p-2 bg-gray-100 rounded">
                {sleepBlock.duration.toFixed(1)} hours
              </div>
              <span className="text-sm text-gray-600">(Recommended: 9 hours)</span>
            </div>
          </div>
        </div>
      </div>

      {/* Block Assignment Controls */}
      <div className="mb-4 bg-white p-4 rounded-lg shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Block Type</label>
            <select
              value={currentMainBlock}
              onChange={(e) => setCurrentMainBlock(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Choose block type...</option>
              {Object.entries(mainBlocks).filter(([key]) => key !== 'sleep' && key !== 'unassigned').map(([key, block]) => (
                <option key={key} value={key}>{block.label}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Activity</label>
            <input
              type="text"
              value={currentActivity}
              onChange={(e) => setCurrentActivity(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter activity name"
            />
          </div>
          <div className="flex items-end">
            <button
              onClick={handleBulkAssignment}
              disabled={!currentMainBlock || !currentActivity}
              className="w-full p-2 bg-blue-600 text-white rounded disabled:bg-gray-400"
            >
              Assign to Selected Blocks
            </button>
          </div>
        </div>
      </div>

      {/* Time Blocks Table */}
      <div className="bg-white p-4 rounded-lg shadow-md overflow-x-auto mb-6">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="p-2 border">Select</th>
              <th className="p-2 border">Time</th>
              <th className="p-2 border">Block Type</th>
              <th className="p-2 border">Activity</th>
              <th className="p-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {timeBlocks.map(block => {
              const isSleepTime = isInSleepBlock(block.startTime);
              const isDisabled = isSleepTime;
              
              return (
                <tr 
                  key={block.id}
                  className={`
                    ${block.selected ? 'bg-blue-50' : ''}
                    ${isDisabled ? 'bg-gray-100' : ''}
                  `}
                  style={{
                    backgroundColor: block.assignedTo ? `${mainBlocks[block.assignedTo].color}15` : '',
                    opacity: isDisabled ? 0.7 : 1
                  }}
                >
                  <td className="p-2 border text-center">
                    <input
                      type="checkbox"
                      checked={block.selected}
                      onChange={() => handleBlockSelection(block.id)}
                      disabled={isDisabled || block.assignedTo}
                      className="w-4 h-4"
                    />
                  </td>
                  <td className="p-2 border">
                    {block.startTime} - {block.endTime}
                    {isSleepTime && <span className="ml-2 text-sm text-purple-600">(Sleep)</span>}
                  </td>
                  <td className="p-2 border">
                    {block.assignedTo ? mainBlocks[block.assignedTo].label : 
                    isSleepTime ? 'Sleep Block' : '-'}
                  </td>
                  <td className="p-2 border">
                    {block.activity || (isSleepTime ? 'Sleep' : '-')}
                  </td>
                  <td className="p-2 border text-center">
                    {!isSleepTime && block.assignedTo && (
                      <button
                        onClick={() => handleDeleteBlock(block.id)}
                        className="text-red-600 hover:text-red-800 p-1 rounded"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Summary Section with Pie Chart */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Schedule Summary</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            {Object.entries(mainBlocks).map(([key, block]) => (
              <div 
                key={key}
                className="flex items-center justify-between p-3 rounded-lg"
                style={{ backgroundColor: `${block.color}15` }}
              >
                <div className="flex items-center space-x-2">
                  <div 
                    className="w-4 h-4 rounded-full"
                    style={{ backgroundColor: block.color }}
                  ></div>
                  <span className="font-medium">{block.label}</span>
                </div>
                <div className="text-right">
                  <div className="font-bold">{summary[key]?.toFixed(1)} hours</div>
                  {block.recommendedHours > 0 && (
                    <div className="text-sm text-gray-600">
                      Recommended: {block.recommendedHours} hours
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          
          <div className="h-64 flex items-center justify-center pie-chart-container">
            <PieChart width={300} height={300}>
              <Pie
                data={pieChartData}
                cx={150}
                cy={125}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="text-center mt-4">
        <button
          onClick={downloadPDF}
          className="p-2 bg-green-600 text-white rounded"
        >
          Download PDF
        </button>
      </div>

{/* Footer Section */}

<div className="text-center mt-6 flex flex-col items-center">
    <img 
        src={logo} 
        alt="Samscape Logo" 
        style={{ width: '100px', marginBottom: '2px' }} // Adjust margin as needed
    />
    <p style={{ marginTop: '0px' }}>Developed by: Abde Syedna Ebrahim Rupawala</p>
</div>
    </div>
  );
}

export default App;